(function () {
  angular.module('pentaApp').factory("resourceHelper.factory", factory);
  factory.$inject = ['$resource', 'Upload'];
  function factory($resource, Upload) {
    /// RETURN
    return {
      defaultResource: defaultResource
    }

    ////////////////////////////////////////
    function defaultResource(url, extraMethods) {
      if (!url) throw new PentaError('ERR NO URL');
      var result = $resource(url, {}, extraMethods);
      var _save = result.save
      result.save = save;
      result.getFileUrl = getFileUrl;
      return result;

      function save(data, done) {
        var key, file;
        Object.keys(data).find(function (f) {
          if (data[f] && (data[f] instanceof File || data[f].constructor.name === 'File')) {
            key = f;
            file = data[f]
            return true
          }
        })
        if (file) {
          var request = { _controller: data._controller };
          request[key] = file;
          request.body = JSON.stringify(data);
          Object.defineProperty(request, '_progress', { value: 0, enumerable: false, writable: true });
          Upload
            .upload({ url: url, data: request })
            .success(function (saved) {
              angular.copy(saved, request)
              if (done) done(saved);
            })
            .progress(function (evt) { request._progress = parseInt(100.0 * evt.loaded / evt.total); });
          return request;
        } else {
          return done ? _save(data, done) : _save(data)
        }
      }

      function getFileUrl(object, filename) {
        if (!filename) return alert('getFileUrl needs filename argument');
        if (!object || typeof object === 'object' && !object._id) return '//:0';
        var queryString = url + '?_file=' + filename;
        queryString += '&_id=' + object._id;
        queryString += '&enterprise=' + enterprise;
        return queryString;
      }
    }
  }


})();
