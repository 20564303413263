/**
 * Created by FERNANDO on 03/05/2016.
 */
'use strict';

(function () {
  var app = angular.module('pentaApp');

  app.config(['$provide', function ($provide) { $provide.decorator('$exceptionHandler', handleFrontendError); }]);
  app.controller('errorController', errorController);

  handleFrontendError.$inject = ['$delegate', '$injector'];
  function handleFrontendError($delegate, $injector) {
    var $scope = null;
    var errorDialog = null;
    var $window = null;
    var errorLog = null;
    var errorVisible = false;
    return function (exception, cause) {
      $delegate(exception, cause);
      if (!errorDialog) {
        $scope = $injector.get('$rootScope').$new(true);
        $scope.close = function () { errorDialog.hide() }
        $window = $injector.get('$window');
        errorLog = $injector.get('errorLog.resource');
        errorDialog = ons
          .createElement('utils/errorAlert.jade', { parentScope: $scope, append: true })
          .then(function (dialog) {
            errorDialog = dialog;
            errorDialog._element[0].addEventListener('posthide', function () { errorVisible = false })
            showError()
          });
      } else {
        showError();
      }
      function showError() {
        if (!errorDialog.show || errorVisible) return; // Puede llegar aca antes de haberse inicializado, y no tengo el metodo show
        errorVisible = true;
        $scope.exception = exception;

        if (exception && !exception._id) {
          if (typeof error === 'string') exception = {
            message: exception,
            name: 'string Error'
          };
          errorLog.save({
            message: exception.message,
            extraInfo: exception._extraInfo,
            name: exception.name,
            source: 'frontend',
            stack: exception.stack,
            browser: ($window.navigator.vendor || "unknown") + " - " + $window.navigator.userAgent,
            _controller: 'errorHandler'
          }, function (loggedError) {
            exception._id = loggedError._id;
            exception.ts = loggedError.ts;
            exception.randId = loggedError.randId;
          });
        }
        if (exception && exception.source)
          $scope.prefix = exception.source.replace(/\.(.)/g, function (a, b) { return b.toUpperCase() });
        if (exception && exception.name !== 'PentaError') exception.message = 'generic';

        errorDialog.show();
      }
    };

  }

  errorController.$inject = ['$scope', 'errorLog.resource', '$window'];
  function errorController($scope, errorLog, $window) {
    var error = $scope.exception;
    if (error && !error._id) {
      if (typeof error === 'string') error = {
        message: error,
        name: 'string Error'
      };
      errorLog.save({
        message: error.message,
        name: error.name,
        source: 'frontend',
        stack: error.stack,
        browser: getBrowser()
      }, function (loggedError) {
        $scope.error._id = loggedError._id;
      });
    }
    $scope.error = error;
    if (error && error.source)
      $scope.prefix = $scope.error.source.replace(/\.(.)/g, function (a, b) { return b.toUpperCase() })

    if (error && error.name !== 'PentaError') error.message = 'generic';

    function getBrowser() {
      return ($window.navigator.vendor || "unknown") + " - " + $window.navigator.userAgent;
    }
  }
})();
