'use strict';
(function () {
  angular.module('pentaApp').factory('alliance.resource', service);

  service.$inject = ['$resource'];
  function service($resource) {
    return $resource('/progressiveApp/api/alliance', {}, {
      get: {
        method: 'GET',
        isArray: true
      }
    });
  }
})();