(function () {
  var _controller = 'translator.component.controller'
  angular.module('pentaApp')
    .controller(_controller,  controller)
    .component('translator', {
      templateUrl: 'components/translator/translator.component.jade',
      controller: _controller,
      bindings: { 
        // '=': Two Way Binding, lo que cambia acá, cambia en el controlador padre automáticamente
        // '<': One Way Binding (RECOMENDADO), para recibir variables, funciones, etc
        // '@': String, para recibir strings, puede ser una bandera por ejemplo
        // '&': Dinámico, se ejecuta lo que está adentro, hay que pasarle un objeto con variables 
        //      ej: componente(call-back='algo(var)')  y en el controller callBack({var: 'esto'})
      }
    })

  controller.$inject = ['$scope', '$element', '$timeout', '$http'];
  function controller($scope, $element, $timeout, $http){
    //// METHODS
    $scope.prevent = prevent;
    $scope.save = save;
    $scope.close = close;
    $scope.clean = clean;

    //// VARS
    $scope.texts = []

    //// WATCHERS

    /// One Way Binding Update (cuando se actualiza tu binding con '<')
    this.$onChanges = function(){ }

    //// INIT
    this.$onInit = function () {
      if(!i18next.originalT) i18next.originalT = i18next.t;
      i18next.t = function(txt){
        var value = i18next.originalT.apply(this, arguments)
        if(txt && !$scope.texts.find(function(f){return f.key === txt}))
          $scope.texts.unshift({key: txt, value: value, original: value})
        return value
      }
    };

    /// DESTROY
    this.$onDestroy = function () {
    };

    ///////////////////////////////////
    function prevent($event){
      $event.stopPropagation();
    }

    function save(){
      var url = '/progressiveApp/' + enterprise + '/translate';
      var withChanges = $scope.texts.filter(function(f){return f.value !== f.original})
      if(!withChanges.length) return ons.notification.toast('Nada para guardar', {timeout: 2000})
      var json = withChanges.reduce(function(trad, text){trad[text.key] = text.value; return trad}, {})
      $http.post(url, json).then(function(){
        ons.notification.toast('Guardado con éxito', {timeout: 2000})
      })
    }

    function clean(){
      $scope.texts = $scope.texts.filter(function(f){return f.value !== f.original})
    }

    function close(){
      $scope.$root.translateTool = false;
    }

    // FIN CONTROLLER
  }
})();
