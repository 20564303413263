'use strict';
(function () {
  var _controller = 'routerSelect.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('routerSelect', {
      templateUrl: 'components/routerSelect/routerSelect.component.jade',
      controller: _controller,
      bindings: {
        onClick: '<', // FUNCTION, se ejecuta al seleccionar un router, devuelve el _id del router seleccionado.
        routers: '<', // ARRAY, array de routers
        selectedRouterId: '<', // id del router seleccionado. ejemplo: $root.defaultRouter
        config: '<', // OBJECT, configuración.
        userPosition: '<'
        //config: {
        //   showLine : Boolean // muestra una linea vertical en los elementos del grupo. (Utilizado en newTurn)
        // }
      }
    })

  controller.$inject = ['$scope', '$element', '$q', '$timeout', 'router.resource', 'group.resource', 'geolocation.factory'];
  function controller($scope, $element, $q, $timeout, routerResource, groupResource, geolocationFactory) {
    // METHODS
    $scope.selectRouter = selectRouter;

    // VARS
    var that = this;
    var ready = false;
    var allRouters = [];
    $scope.routers = [];
    $scope.groups = [];

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('routers', function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!newVal.find(function (f) { return f._distanceToUser })) return
      else if (ready) return
      else {
        ready = true;
        processRouters();
        sortRouterByDistanceToUser();
        setGroups();
        $scope.routersDistanceReady = true;
      }
    }, true)

    this.$onChanges = function (bind) {
      if (bind.selectedRouterId && bind.selectedRouterId.currentValue
        && bind.selectedRouterId.previousValue !== bind.selectedRouterId.currentValue) {
        $scope.selectedRouterId = bind.selectedRouterId.currentValue;
        setActiveRouter(bind.selectedRouterId.currentValue);
      }
      if (bind.routers && bind.routers.previousValue && bind.routers.currentValue
        && bind.routers.previousValue.length !== bind.routers.currentValue.length) {
        filterRouters(bind.routers.currentValue);
      }
    }
    // INIT

    /// On component init
    this.$onInit = function () {
      if (!this.onClick) return console.error('ERR falta declarar la función al seleccionar un router.')
      $scope.onClick = this.onClick || null;
      $scope.config = this.config || {};
      $scope.selectedRouterId = this.selectedRouterId || null;
      refresh();
    };

    /////////////////////
    function refresh() {
      var groups = groupResource.query({ _select: ['name', 'description', 'type'], type: 'ROUTER' });
      $q
        .all([groups.$promise])
        .then(function () {
          $scope.groups = groups;
          allRouters = that.routers || $scope.$root.routers;
          $scope.routers = allRouters;
          filterRouters(allRouters);
          getDistanceAndSetRoutersDistance();
        })
        .catch(function (err) { if (err) throw new PentaError(err); })
    }

    function getDistanceAndSetRoutersDistance() {
      if (that.userPosition) {
        $scope.routersDistanceReady = true
        return setRoutersDistance(that.userPosition)
      }
      $scope.routersDistanceReady = false;
      // Esto es una protección por si falla la obtención de la distancia.
      $timeout(function () { $scope.routersDistanceReady = true; }, 8000)
      geolocationFactory.getCurrentPosition(setRoutersDistance,
        function () {
          geolocationFactory.estimatePosition()
            .then(setRoutersDistance)
            .catch(function () {
              $scope.routersDistanceReady = true;
              if (!$scope.$$phase && !$scope.$root.$$phase) $scope.$apply();
            })
          $scope.badPrecision = true;
        });
    }

    function setRoutersDistance(position) {
      if (!position || !position.latitude || !position.longitude) return
      var routersFiltered = allRouters.filter(function (router) {
        if (!router.geolocalization || !router.geolocalization.coordinates) return;
        if (!router.geolocalization.coordinates[0] || !router.geolocalization.coordinates[1]) return;
        var lat = router.geolocalization.coordinates[0];
        var lng = router.geolocalization.coordinates[1];
        if (!(lat > -90 && lat < 90) || !(lng > -180 && lng < 180)) return;
        return true;
      })
      routersFiltered.forEach(function (router) {
        var routerPosition = router.geolocalization.coordinates;
        router._distanceToUser = geolocationFactory.getDistance(routerPosition[0], routerPosition[1], position.latitude, position.longitude)
      });
      // if (!$scope.$$phase) $scope.$apply();
    }

    function processRouters() {
      if (!$scope.routers || !$scope.routers.length) return
      $scope.routers.forEach(function (router) { if (!router._distanceToUser) router._distanceToUser = 0 })
    }

    function sortRouterByDistanceToUser() {
      if (!$scope.routers || !$scope.routers.length) return
      $scope.routers.sort(function (a, b) { return a._distanceToUser - b._distanceToUser });
    }

    function setGroups() {
      if (!$scope.routers || !$scope.routers.length) return
      $scope.routerGroups = {};
      $scope.routers.forEach(function (f) {
        if (!f.groups || !f.groups.length) {
          if (!$scope.routerGroups['']) $scope.routerGroups[''] = [];
          $scope.routerGroups[''].push(f);
        } else {
          f.groups.forEach(function (group) {
            if (!$scope.routerGroups[group]) $scope.routerGroups[group] = [];
            $scope.routerGroups[group].push(f);
          })
        }
      })
      setActiveRouter($scope.selectedRouterId);
    }

    function selectRouter(router) {
      if (!router) return
      if (typeof $scope.onClick === 'function') $scope.onClick(router);
    }

    function setActiveRouter(router) {
      if (!router || !$scope.routerGroups) return
      for (var key in $scope.routerGroups) {
        if (!router || !key || key === '') continue
        else if ($scope.routerGroups[key].find(function (f) { return f._id === router })) $scope.routerGroups[key]._show = true;
        else $scope.routerGroups[key]._show = false;
      }
    }

    function filterRouters(routers) {
      if (!routers || !routers.length || !$scope.routers || !$scope.routers.length) return
      $scope.routers = allRouters;
      $scope.routers = $scope.routers.filter(function (router) { return routers.find(function (f) { return f._id === router._id }) });
      setGroups();
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();
