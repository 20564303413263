'use strict';
(function () {
  angular.module('pentaApp').factory('reservation.resource', service);

  service.$inject = ['$resource', 'Upload'];
  function service($resource, Upload) {
    var url = '/progressiveApp/api/reservation'
    var result = $resource(url, {}, {
      get: { method: 'GET', cache: false },
      query: { method: 'GET', isArray: true, cache: false },
      doReservation: {
        method: 'POST',
        isArray: false
      },
      cancelReservation: {
        method: 'PUT',
        isArray: false
      }
    });

    var _doReservation = result.doReservation;
    result.doReservation = doReservation;
    return result

    function doReservation(data, done) {
      if (data.additionalInformation && data.additionalInformation.filter(function (f) { return f.file && !Array.isArray(f.file) }).length) {
        // Hay al menos un archivo adjunto
        var rq = { _controller: data._controller };
        data.additionalInformation.forEach(function (f) {
          if (!f.file || Array.isArray(f.file)) return
          var encoded = btoa(f.file.name);
          rq[encoded] = f.file;
        })
        rq.body = JSON.stringify(data);
        Object.defineProperty(rq, '_progress', { value: 0, enumerable: false, writable: true });
        Upload
          .upload({ url: url, data: rq })
          .success(function (saved) {
            angular.copy(saved, rq)
            if (done) done(saved);
          })
          .progress(function (evt) { rq._progress = parseInt(100.0 * evt.loaded / evt.total); });
        return rq;
      } else {
        return done ? _doReservation(data, done) : _doReservation(data)
      }
    }
  }

})();