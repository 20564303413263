'use strict';
(function () {
  var _controller = 'customInquery.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'customInquery.resource', '$q'];
  function controller($scope, customInqueryResource, $q) {
    /// METHODS
    $scope.save = save;
    $scope.invalid = invalid;

    /// VARS
    $scope.profile = profile;
    var onClose = appNavigator.topPage.data.onClose;

    $scope.customInquery = appNavigator.topPage.data.customInquery;
    $scope.dontSave = appNavigator.topPage.data.dontSave;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT
    
    /////////////////////

    function save() {
      $scope.customInquery.questions = $scope.customInquery.questions.filter(function(f){return !f.separator})
      var toSave = $scope.customInquery.questions.map(function(m){
        return {
          customInquery: $scope.customInquery._id,
          question: m._id,
          option: m.selected,
          text: m.response,
        }
      }) 

      /// En caso que no se quiera guardar, se devuelve el array listo para ser guardado en otro momento
      if($scope.dontSave) return close(toSave);

      /// Caso contrario, se guarda uno por uno y se espera a que termine para cerrar el modal
      $q.all(toSave.map(function(m){return customInqueryResource.save(m).$promise}))
        .then(function(){ close(); })
        .catch(function(){ close(); })
    }


    function close(data){
      appNavigator.popPage({callback: function(){
        if(onClose) onClose(data);
      }});
    }


    function invalid(){
      if(!$scope.customInquery || !$scope.customInquery.questions) return true;
      if($scope.customInquery.questions.find(function(f){return !f.separator && !f.response && !f.selected})) return true
      return false
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
  }

})();
