'use strict';
(function () {
  var _controller = 'home.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$q', 'flyer.resource', 'hq.socket'];
  function controller($scope, $q, flyerResource, hqSocket) {
    // METHODS


    // VARS
    $scope.allFlyers = [];
    $scope.categories = ['General'];
    $scope.flyerResource = flyerResource;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$on('defaultRouter', initCarousel);
    $scope.$on('login', initCarousel)

    // INIT
    initCarousel();

    /////////////////////
    function initCarousel() {
      var flyers = flyerResource.query({ _controller: _controller, destination: 'APP-HOME' });
      $q
        .all([flyers.$promise])
        .then(function () {
          if (!flyers.length) pushDefaultFlyers(flyers); //- Flyers por default cuando no hay ninguno configurado.
          //- Flyers
          flyers = window.flyerFilter(flyers, $scope.$root.defaultRouter); //- filtra condiciones requeridas
          if (!profile._id) flyers = flyers.filter(function (f) { return !f.inquery })
          flyers.forEach(function (f) { f.categories = ['General'] }) //- asigna categoria general a todos los flyers.
          $scope.allFlyers = flyers;
        })
        .catch(function (err) { if (err) throw new PentaError(err) })
    }

    function pushDefaultFlyers(flyers) {//- Flyers por default cuando no hay ninguno configurado.
      flyers.push({ mediaType: 'IMAGE', directUrl: '/frontend/static/img/defaultPwaHome.png', match: {} })
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
  }

})();
