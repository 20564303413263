'use strict';
(function () {
  var _controller = 'notifications.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'notification.resource', 'hq.socket', 'reservation.resource'];
  function controller($scope, notificationResource, hqSocket, turnResource) {
    // METHODS
    $scope.execAction = execAction;
    $scope.removeAll = removeAll;

    // VARS

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    hqSocket.on('NOTIFICATION', refresh)

    // INIT
    init()

    /////////////////////
    function init() {
      refresh()
    }
    function refresh() {
      notificationResource.query({
        _controller: _controller, _select: ['*'], _sortBy: '-createTS', _paginate: false
      }, function (data) {
        $scope.notifications = data;
        data.forEach(function (f) {
          if (!f.actions) f.actions = [];
          f.actions.push({ title: "Eliminar", default: false, type: 'REMOVE-ITEM' })
        })
      })
    }

    function execAction(notification, action) {
      // Ejecutar la acción
      if (!action) action = notification.actions.find(function (f) { return f.default });
      if (!action) return // No hay ninguna acción por defecto
      if (action.type !== 'REMOVE-ITEM') {
        /// Marcar como leida
        notificationResource.save({ _id: notification._id, readed: true, _controller: _controller }, function () {
          // Eliminar de la campanita
          removeFromNotificationArray(notification, $scope.$root.appNotifications)
        });
      }
      switch (action.type) {
        case 'OPEN-SECTION':
          $scope.$root.openSection(action.value);
          break;
        case 'OPEN-CHAT':
          var values = action.value.split('@@');
          turnResource.query({ myReservations: true, _controller: _controller, _ignoreVeil: true }, function (data) {
            if (!data.length) return openReadOnlyChat(values) // si se intenta abrir el chat de una reserva y no hay reservas activas abrir en modo read-only.
            var reservation = data.find(function (f) { return f._id === values[1] })
            if (!reservation) return openReadOnlyChat(values) // si se intenta abrir el chat de una reserva y no hay reservas activas abrir en modo read-only.
            appNavigator.pushPage('chat/chat.jade', { data: { title: values[0], relatedObject: values[1], relatedObjectType: values[2] } })
          })
          break;
        case 'OPEN-NPS':
          debugger
          var values = action.value.split('@@');
          appNavigator.pushPage('nps/nps.jade', {
            data: {
              origin: values[0],
              reservation: values[0] === 'RESERVATION' ? values[1] : undefined,
              order: values[0] === 'STORE' ? values[1] : undefined,
              router: values[2],
              onResponse: function () {
                var action = { type: 'REMOVE-ITEM' }
                execAction(notification, action);
              }
            },
          })
          break;
        case 'REMOVE-ITEM':
          notificationResource.remove({ _id: notification._id, _controller: _controller }, function () {
            // Eliminar de la lista
            removeFromNotificationArray(notification, $scope.notifications)
            // Eliminar de la campanita
            removeFromNotificationArray(notification, $scope.$root.appNotifications)
          })
          break;
      }
    }

    function openReadOnlyChat(values) {
      ons.notification.confirm({
        message: 'Este turno ya fue finalizado, desea abrir el chat en modo lectura?',
        title: 'Confirmación'
      })
        .then(function (val) {
          if (val)
            appNavigator.pushPage('chat/chat.jade', {
              data: {
                title: values[0],
                relatedObject: values[1],
                relatedObjectType: values[2],
                readOnly: true
              }
            })
        })
    }

    function removeFromNotificationArray(notification, notifications) {
      for (var i = 0; i < notifications.length; i++)
        if (notifications[i]._id === notification._id) {
          notifications.splice(i, 1)
          break;
        }
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
      hqSocket.removeListener('NOTIFICATION', refresh)
    }

    function removeAll() {
      ons.notification.confirm({
        message: i18next.t('¿Está seguro que desea eliminar todas las notificaciones?'),
        title: i18next.t('Confirmar')
      })
        .then(function (data) {
          if (data === 1)
            notificationResource.remove({
              _controller: _controller,
              client: profile._id
            }, function (data) {
              $scope.notifications = [];
              $scope.$root.appNotifications = [];
            })
        })
        .catch(function () { })
    }

    //Fin del controlador
  }

})();
