'use strict';
(function () {
  angular.module('pentaApp').factory('chat.resource', service);

  service.$inject = ['$resource', 'Upload'];
  function service($resource, Upload) {
    var url = '/progressiveApp/api/chat'
    var result = $resource(url, {}, {
      query: { method: 'GET', isArray: true, cache: false },
      getFileUrl: { method: 'GET', isArray: true, cache: true },
      save: { method: 'POST', isArray: false }
    });
    var _save = result.save
    result.save = save;
    result.getFileUrl = getFileUrl;
    return result;

    function save(data, done) {
      if (data.file) {
        var request = { _controller: data._controller };
        request[data.key] = data.file;
        request.body = JSON.stringify(data);
        Object.defineProperty(request, '_progress', { value: 0, enumerable: false, writable: true });
        Upload
          .upload({ url: url, data: request })
          .success(function (saved) {
            angular.copy(saved, request)
            if (done) done(saved);
          })
          .progress(function (evt) { request._progress = parseInt(100.0 * evt.loaded / evt.total); });
        return request;
      } else {
        return done ? _save(data, done) : _save(data)
      }
    }

    function getFileUrl(object, filename) {
      if (!filename) return alert('getFileUrl needs filename argument');
      if (!object || typeof object === 'object' && !object._id) return '//:0';
      var queryString = url + '?_file=' + filename;
      queryString += '&_id=' + object._id;
      queryString += '&enterprise=' + enterprise;
      return queryString;
    }

  }
})();